import React, { useState, useContext, useEffect } from "react";
import { Col, Card, CardBody, Button, Alert } from "reactstrap";
import EyeIcon from "mdi-react/EyeIcon";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { POST, GetUserInfo } from "../../../utils/api";
import { authContext } from "../../../contexts/AuthContext";
import { validateProfileForm } from "../../../utils/validate";

export default ({ email, accounts, setAccounts }) => {
  const { auth } = useContext(authContext);

  const [showPassword, setShowPassword] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formSucceed, setFormSucceed] = useState(false);

  let originalInfo = {
    org: "",
    fname: "",
    lname: "",
    credits: 0,
    password: ""
  };
  const [formData, setFormData] = useState({
    org: "",
    fname: "",
    lname: "",
    credits: 0,
    password: ""
  });
  const [error, setFormError] = useState({
    org: "",
    fname: "",
    lname: "",
    credits: "",
    form: "",
    password: ""
  });

  useEffect(() => {
    if (!!email) {
      GetUserInfo(email, auth.token)
        .then((res) => res.data.data)
        .then((data) => {
          originalInfo = data;
          setFormData({
            ...formData,
            email,
            org: data?.org ? data?.org : "",
            fname: data?.fname ? data?.fname : "",
            lname: data?.lname ? data?.lname : "",
            credits: data?.creditsRemaining ? data?.creditsRemaining : 0,
          });
        })
        .catch((err) => {
          console.log(err);
          setFormData(originalInfo);
        });
    }
  }, [email]);

  const update = async () => {
    setFormLoading(true);

    let isValid = await validateProfileForm(formData, setFormError);
    if (!isValid) {
      setFormLoading(false);
      return;
    }
    var data = formData;
    data.email = email;

    POST(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/update`,
      data,
      auth.token
    )
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "success") {
          setFormLoading(false);
          accounts = accounts.map((v) => {
            if (v.value === email)
              v.label = formData.fname + " " + formData.lname;
            return v;
          });
          setAccounts(accounts);
          NotificationManager.success(
            "The User info has been updated!",
            "Success"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Something went wrong", "Error");
      });
  };

  const cancel = () => {
    setFormData(originalInfo);
    setFormError({
      org: "",
      fname: "",
      lname: "",
      credits: "",
      form: "",
    });
  };

  return (
    <Col md={12} lg={9}>
      <NotificationContainer />
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">User Account</h5>
            <h5 className="subhead">Edit your details and click update</h5>
          </div>
          <form className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">First Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={(e) =>
                      setFormData({ ...formData, fname: e.target.value })
                    }
                    value={formData.fname}
                  />
                  {error.fname && (
                    <span className="form__form-group-error">
                      {error.fname}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Surname</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Surname"
                    onChange={(e) =>
                      setFormData({ ...formData, lname: e.target.value })
                    }
                    value={formData.lname}
                  />
                  {error.lname && (
                    <span className="form__form-group-error">
                      {error.lname}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Company Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Company name"
                    onChange={(e) =>
                      setFormData({ ...formData, org: e.target.value })
                    }
                    value={formData.org}
                  />
                  {error.org && (
                    <span className="form__form-group-error">{error.org}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Credits Remaning</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Credits Number"
                    onChange={(e) =>
                      setFormData({ ...formData, credits: e.target.value })
                    }
                    value={formData.credits}
                  />
                  {error.credits && (
                    <span className="form__form-group-error">
                      {error.credits}
                    </span>
                  )}
                </div>
              </div>
            </div>
            
            <div className="form__form-group">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    value={formData.password}
                  />
                  {error.password && (
                    <span className="form__form-group-error">{error.password}</span>
                  )}
                </div>
              </div>
            </div>
          </form>

          
          <Button color="primary" onClick={update} disabled={formLoading}>
            Update
          </Button>
          <Button color="secondary" onClick={cancel}>
            Cancel
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};
