import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import Select from "react-select";

import AccountForm from "./components/AccountForm";
import { GetAllUsers } from "../../utils/api";
import { authContext } from "../../contexts/AuthContext";

export default () => {
  const { auth } = useContext(authContext);
  const [accounts, setAccounts] = useState([]);
  const [accountEmail, setAccountEmail] = useState(null);
  useEffect(() => {
    GetAllUsers(auth.token)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "success") {
          setAccounts(data.data);
          setAccountEmail(data.data[0].value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Search and edit an account</h3>
          <h3 className="page-subhead subhead">Search for a user account</h3>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12} lg={9}>
          <Select
            options={accounts}
            className="react-select"
            classNamePrefix="react-select"
            onChange={(selectedOption) => setAccountEmail(selectedOption.value)}
            placeholder="Select the venue"
            value={accounts.filter((option) => option.value === accountEmail)}
          />
        </Col>
      </Row>
      <Row>
        <AccountForm
          email={accountEmail}
          accounts={accounts}
          setAccounts={setAccounts}
        />
      </Row>
    </Container>
  );
};
