import { POST } from "../utils/api";

// Check if the email is valid
const isEmailValid = (value) => {
  var email_regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email_regx.test(String(value).toLowerCase())) return true;
  return false;
};
const errors = {
  org: {
    invalid: "Company name should be more than 3 letters",
    required: "Please input your company name.",
  },
  fname: {
    invalid: "First name should be more than 3 letters",
    required: "Please input your first name.",
  },
  lname: {
    invalid: "Last name should be more than 3 letters",
    required: "Please input your last name.",
  },
  credits: {
    required: "Please read and check the checkbox.",
  },
  email: {
    invalid: "Please input the valid email address.",
    existing: "The address is already existing.",
    required: "Please input your email address.",
  },
  password: {
    invalid: "Password should be more than 6 letters",
    required: "Please input your password.",
  }
};

// Subscribe form validation
const validateField = (name, value) => {
  const optional = ["mobile", "avatar", "password", "town", "city", "capacity"];

  if (!optional.includes(name) && value === "") {
    console.log(name);
    return errors[name]["required"];
  }

  if (
    (name === "cname" && value.length < 3) || // Invalid org name
    (name === "fname" && value.length < 3) || // Invalid first name
    (name === "lname" && value.length < 3) // Invalid last name
  ) {
    return errors[name]["invalid"];
  }
  if (name === "password" && value.length < 6 && value) {
    return errors[name]["invalid"];
  }

  return "";
};

export const validateLoginForm = (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  if (formData.email === "") {
    formErrors["email"] = errors["email"]["required"];
    isValid = false;
  } else if (isEmailValid(formData.email) === false) {
    formErrors["email"] = errors["email"]["invalid"];
    isValid = false;
  }
  if (formData.password === "") {
    formErrors["password"] = errors["password"]["required"];
    isValid = false;
  } else if (formData.password.length < 6) {
    formErrors["password"] = errors["password"]["invalid"];
    isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};

export const validateProfileForm = (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  for (var key of Object.keys(formData)) {
    let valid = validateField(key, formData[key]);
    formErrors[key] = valid;
    if (valid !== "") isValid = false;
  }

  setFormErrors(formErrors);
  return isValid;
};
