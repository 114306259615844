import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";

import PrivateRoute from "./components/PrivateRoute";

import LogIn from "../LogIn/index";
import Account from "../Account/index";

const Pages = () => (
  <Switch>
    <Route path="/pages/account" component={Account} />
    <Redirect to="/pages/account" />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/pages" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
