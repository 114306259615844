import React, { useState, useContext } from "react";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Link } from "react-router-dom";
import { Alert, Button } from "reactstrap";

import { authContext } from "../../../contexts/AuthContext";
import { validateLoginForm } from "../../../utils/validate";
import { POST } from "../../../utils/api";

const LogInForm = (props) => {
  const { setAuthData } = useContext(authContext);

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setFormError] = useState({
    email: "",
    password: "",
    form: "",
  });

  const login = (e) => {
    e.preventDefault();

    let isValid = validateLoginForm(formData, setFormError);

    if (!isValid) return;

    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`, formData)
      .then((res) => res.data)
      .then((data) => {
        setAuthData(data);
      })
      .catch((err) => {
        setFormError({ form: "The credentials are invalid." });
      });
  };

  return (
    <form className="form" onSubmit={login} autoComplete="on">
      <Alert color={"danger"} isOpen={!!error.form} className="w-100">
        {error.form}
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <div className="form__form-group-input-wrap">
            <input
              name="email"
              type="email"
              placeholder="Email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            {error.email && (
              <span className="form__form-group-error">{error.email}</span>
            )}
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>

          <div className="form__form-group-input-wrap">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            {error.password && (
              <span className="form__form-group-error">{error.password}</span>
            )}
          </div>
          <button
            className={`form__form-group-button${
              showPassword ? " active" : ""
            }`}
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            <EyeIcon />
          </button>
        </div>
      </div>

      <Button
        color="primary"
        className="account__btn account__btn--small mt-2"
        type="submit"
      >
        Sign In
      </Button>
    </form>
  );
};

export default LogInForm;
