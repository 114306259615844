import React, { useContext } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { authContext } from "../../../contexts/AuthContext";
import { useState } from "react";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default () => {
  const { auth, setAuthData } = useContext(authContext);
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };
  const logout = () => {
    setAuthData({ ...auth, token: "", name: "" });
  };

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={toggle}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">{auth.name}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && (
        <button type="button" className="topbar__back" onClick={toggle} />
      )}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Account" icon="user" path="/pages/account" />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            path="/"
            onClick={logout}
          />
        </div>
      </Collapse>
    </div>
  );
};
