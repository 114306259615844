import axios from "axios";

const createAxios = (token) => {
  const config = {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  const instance = axios.create(config);

  return instance;
};

export const POST = (url, data, token = null) =>
  createAxios(token).post(url, data);
export const GET = (url, params, token = null) =>
  createAxios(token).get(url, { params });

export const GetAllAddresses = async (postcode, token) => {
  if (postcode.length < 6) {
    return [];
  }

  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/user/postcode/${postcode}`,
    {},
    token
  );

  let addresses = [];
  for (var fa of data.addresses) {
    addresses.push({ value: fa, label: fa });
  }
  return addresses;
};

export const GetAllUsers = async (token) => {
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/user/all`;
  let data = await POST(api_addr, {}, token);
  return data;
};

export const GetUserInfo = async (email, token) => {
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/user/info`;
  let data = await POST(api_addr, { email }, token);
  return data;
};
